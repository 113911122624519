import React, { useContext } from 'react'
import renderBloks from '@renderBloks'
import SbEditable from 'storyblok-react'
import { Box } from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'
import { H3, H5, TextSm, ContentContainer } from '@system'
import { Link } from 'gatsby'
import PageContainer from '@system/page-container'
import { ThemeProvider } from '@material-ui/styles'
import CssBaseline from '@material-ui/core/CssBaseline'
import ACC_THEME from '../../themes'
import PageContext from '@context'
import MeetupDetail from './meetup-detail'
import Layout from '../layout'

const useStyles = makeStyles((theme) => ({
  body: {
    marginTop: '-580px',
    paddingTop: '125px',
    position: 'relative',
    '&>div:first-child': {
      background: ACC_THEME.palette.common.white,
    },
    paddingBottom: '0px',
    [ACC_THEME.breakpoints.down('md')]: {
      padding: '0px 20px 20px 20px',
    },
    [ACC_THEME.breakpoints.down('sm')]: {
      paddingTop: '90px !important',
    },
  },
  outerContainer: {
    background:
      'linear-gradient(106.52deg, #1858A8 -3.9%, rgba(24, 88, 168, 0) 52.24%), #182F4E',
    minHeight: '600px',
  },
  outer: {
    backgroundColor: ACC_THEME.palette.common.white,
    margin: '-80px auto auto auto',
    padding: '60px 70px',
    maxWidth: '100%',
    [ACC_THEME.breakpoints.between('md', 'lg')]: {
      padding: '60px 60px',
    },
    [ACC_THEME.breakpoints.down('xs')]: {
      padding: '35px',
      '& form': {
        '& h4': {
          textAlign: 'center',
          padding: '0px 10px',
        },
        '&>div>div': {
          width: '100%',
        },
      },
    },
  },
  formatTitle: {
    display: 'flex',
    textAlign: 'left',
    padding: '5px 5px',
    color: ACC_THEME.palette.common.black,
    fontWeight: ACC_THEME.typography.fontWeightMedium,
  },
  topicLinks: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-end',
    width: '25%',
    [ACC_THEME.breakpoints.down('sm')]: {
      width: '100%',
    },
  },
  topicLink: {
    backgroundColor: ACC_THEME.palette.background.whiteSmoke,
    textDecoration: 'none',
    color: ACC_THEME.palette.secondary.main,
    fontWeight: ACC_THEME.typography.fontWeightMedium,
    padding: '1.2rem',
    margin: '0.5rem',
    width: '100%',
  },
  title: {
    color: ACC_THEME.palette.secondary.main,
    lineHeight: '50px',
    display: 'flex',
  },
  contentColumn: {
    width: (props) => '50%',
    [ACC_THEME.breakpoints.between('sm', 'md')]: {
      width: '60%',
    },
    [ACC_THEME.breakpoints.down('sm')]: {
      width: '100% !important',
      paddingBottom: '40px',
    },
  },
  flexBox: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'space-between',
    backgroundColor: ACC_THEME.palette.common.white,
    position: 'relative',
  },
}))

const topics = {
  'project-managers': {
    name: 'Project Managers',
    slug: 'project-managers',
  },
  'field-operations-managers': {
    name: 'Field Operations Managers',
    slug: 'field-ops-managers',
  },
  'preconstruction-managers': {
    name: 'Preconstruction Managers',
    slug: 'precon-managers',
  },
  'bim-managers': {
    name: 'BIM Managers',
    slug: 'bim-managers',
  },
}

const MeetupTopic = (props) => {
  const classes = useStyles(props)
  const pageContext = useContext(PageContext)
  const { metaData, body, canonicalPage, topicName } = props.blok
  const meetUpsComing =
    !!pageContext.meetUps &&
    pageContext.meetUps
      .filter((webinar) => webinar.content.topic === props.blok.topicName)
      .slice()
  const topicLinks = Object.keys(topics).filter((key) => key !== topicName)

  return (
    <Layout metaData={metaData} canonicalPage={canonicalPage}>
      <ThemeProvider theme={ACC_THEME}>
        <CssBaseline />
        <SbEditable content={props.blok}>
          <Box className={classes.outerContainer} />
          <Box className={classes.body}>
            <PageContainer>
              <ContentContainer className={classes.outer}>
                <Box className={classes.formatTitle}>
                  <TextSm>{'AUTODESK CONSTRUCTION MEETUPS'}</TextSm>
                </Box>
                <Box className={classes.flexBox}>
                  <Box className={classes.contentColumn}>
                    {props.blok.topicName && (
                      <Box>
                        <H3 component="h1" className={classes.title}>
                          {topics[topicName].name}
                        </H3>
                      </Box>
                    )}
                    <Box className={classes.formatTitle}>
                      <H5 className={classes.description}>
                        {renderBloks(props.blok.description)}
                      </H5>
                    </Box>
                  </Box>

                  <Box className={classes.topicLinks}>
                    {!!topicLinks.length &&
                      topicLinks.map((link) => (
                        <Link
                          key={link}
                          className={classes.topicLink}
                          to={`/meetups/${topics[link].slug}`}
                        >
                          {topics[link].name}
                        </Link>
                      ))}
                  </Box>
                </Box>
              </ContentContainer>
            </PageContainer>
          </Box>
        </SbEditable>
        <Box>
          {!!meetUpsComing &&
            meetUpsComing
              .slice()
              .map(
                ({
                  uuid,
                  content: {
                    title,
                    guestSpeakers,
                    signUp,
                    meetupDate,
                    description,
                    isAutodeskUniversityMeetup,
                  },
                }) => {
                  return (
                    <Box key={uuid}>
                      <MeetupDetail
                        title={title}
                        guestSpeakers={guestSpeakers}
                        signUp={signUp}
                        meetupDate={meetupDate}
                        description={description}
                        isAutodeskUniversityMeetup={isAutodeskUniversityMeetup}
                      />
                    </Box>
                  )
                }
              )}
        </Box>
        {!!body && !!body.length > 0 && renderBloks(body)}
      </ThemeProvider>
    </Layout>
  )
}

export default MeetupTopic
